"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./polyfills");
var identifierAttributes = [
    'dc.identifier',
    'citation_doi',
    'eprints.id_number',
    'eprints.official_url',
    'bepress_citation_doi',
    'prism.doi',
    'dc.identifier.doi',
];
function parseDoi(identifier) {
    var withoutScheme = identifier.replace(/^((info:)?doi:)|(https?:\/\/(dx\.)?doi.org\/)/, '');
    // A DOI is `10.`, followed by a number of digits, then a slash, and then any number of characters
    return /^10\.\d{4,}\/.*$/.test(withoutScheme) ? withoutScheme : null;
}
function isNotNull(value) {
    return value !== null;
}
function getDois(domTree) {
    var metaNameTags = Array.from(domTree.querySelectorAll('meta[name]'));
    var metaNameTagsWithIdentifier = metaNameTags
        .filter(function (tag) { return identifierAttributes.indexOf(tag.getAttribute('name').toLowerCase()) !== -1; });
    var metaPropertyTags = Array.from(domTree.querySelectorAll('meta[property]'));
    var metaPropertyTagsWithIdentifier = metaPropertyTags
        .filter(function (tag) { return identifierAttributes.indexOf(tag.getAttribute('property').toLowerCase()) !== -1; });
    var metaTagsWithIdentifier = metaNameTagsWithIdentifier.concat(metaPropertyTagsWithIdentifier)
        .filter(function (tag) {
        var scheme = tag.getAttribute('scheme');
        return scheme === null || scheme === '' || scheme.toLowerCase() === 'doi';
    });
    var identifiers = metaTagsWithIdentifier.map(function (tag) { return tag.getAttribute('content'); }).filter(isNotNull);
    // Open Journal Systems does not necessarily list the DOI in the <head>,
    // but if `domTree` includes the <body>, we can at least try to find the link
    // there:
    var ojsDoiLink = domTree.querySelector('.pkp_structure_main .doi .value a');
    if (ojsDoiLink) {
        var ojsDoiLinkHref = ojsDoiLink.getAttribute('href');
        if (typeof ojsDoiLinkHref === 'string') {
            identifiers.push(ojsDoiLinkHref);
        }
    }
    var dois = identifiers.map(parseDoi).filter(isNotNull);
    var uniqueDois = dois.filter(function (doi, index) { return dois.indexOf(doi) === index; });
    return uniqueDois;
}
exports.getDois = getDois;
