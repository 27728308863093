import { iframeResizer } from "iframe-resizer";
import { getDois } from "get-dois";

(function () {
  const now = new Date(Date.now());
  if (now.getFullYear() <= 2023 && now.getMonth() < 9) {
    console.log(
      "Plaudit is disabled until the end of the month, apologies for the inconvenience.",
    );
    return;
  }
  const baseUrl = process.env.APP_URL ?? "https://plaudit.pub";

  const insertWidget = (element: HTMLElement) => {
    if (element.parentNode === null) {
      return;
    }

    const pidParam = element.dataset.pid;
    const metaDois = getDois(document);
    const rawPid = pidParam || (metaDois[0] ? `doi:${metaDois[0]}` : null);
    const rawEmbedderId = element.dataset.embedderId;
    const rawReferrer = document.location
      ? document.location.toString()
      : "non-browser";

    if (rawPid === null || typeof rawEmbedderId === "undefined") {
      // No article to endorse found; do not include the Plaudit widget.
      return;
    }
    const pid = encodeURIComponent(rawPid);
    const embedderId = encodeURIComponent(rawEmbedderId);
    const referrer = encodeURIComponent(rawReferrer);

    const widget = document.createElement("iframe");
    widget.setAttribute(
      "src",
      `${baseUrl}/widget/?pid=${pid}&embedder_id=${embedderId}&referrer=${referrer}`,
    );
    widget.setAttribute("title", "Endorsements for the article on this page");
    widget.setAttribute("scrolling", "no");
    // The min-width-instead-of-width hack here works around an iOS issue according to iframe-resizer:
    // https://www.npmjs.com/package/iframe-resizer#typical-setup
    widget.style.width = "1px";
    widget.style.minWidth = "100%";
    widget.style.borderWidth = "0";

    element.parentNode.insertBefore(widget, element);

    iframeResizer(
      {
        heightCalculationMethod: "taggedElement",
        checkOrigin: false,
      },
      widget,
    );
  };

  const embedSrc = `${baseUrl}/embed/endorsements.js`;

  Array.from(
    document.querySelectorAll<HTMLScriptElement>(`script[src="${embedSrc}"]`),
  ).forEach(insertWidget);
})();
